var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"work-info"},[_c('div',{staticClass:"work-container"},[_c('v-row',{directives:[{name:"scroll",rawName:"v-scroll:#scroll-target",value:(_vm.onScroll),expression:"onScroll",arg:"#scroll-target"}],staticClass:"title-row",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('TitleEffect',{attrs:{"title":'Projects',"scrollVariable":_vm.scrollVariable,"effectEnd":_vm.starter,"difference":600,"primaryColorHex":_vm.color.hex}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"card-col",attrs:{"cols":"auto"}}),_c('v-col',{staticClass:"card-col",attrs:{"cols":"auto","align":"left"}},[_c('Card',{attrs:{"isDirectionLeft":false,"effectEnd":_vm.cardMovement(1),"difference":400,"jobDetails":{
                        companyName: 'Snakes Game using Linked List',
                        jobTitle: 'Side Project',
                        description: _vm.snakes,
                    }}})],1),_c('v-col',{staticClass:"card-col",attrs:{"align":"right","cols":"auto"}},[_c('Card',{attrs:{"isDirectionLeft":true,"effectEnd":_vm.cardMovement(2),"difference":400,"jobDetails":{
                        companyName: 'Revent App',
                        jobTitle: 'Side Project',
                        description: _vm.revent,
                    }}})],1),_c('v-col',{staticClass:"card-col",attrs:{"cols":"auto"}}),_c('v-col',{staticClass:"card-col",attrs:{"cols":"auto"}}),_c('v-col',{staticClass:"card-col",attrs:{"cols":"auto","align":"left"}},[_c('Card',{attrs:{"isDirectionLeft":false,"effectEnd":_vm.cardMovement(3),"difference":400,"jobDetails":{
                        companyName: 'Organic Bar',
                        jobTitle: 'Side Project',
                        description: _vm.organic,
                    }}})],1),_c('v-col',{staticClass:"card-col",attrs:{"cols":"auto","align":"right"}},[_c('Card',{attrs:{"isDirectionLeft":true,"effectEnd":_vm.cardMovement(4),"difference":400,"jobDetails":{
                        companyName: 'Mass. Audubon Society',
                        jobTitle: 'Clark University',
                        description: _vm.capstone,
                    }}})],1),_c('v-col',{staticClass:"card-col",attrs:{"cols":"auto"}}),_c('v-col',{staticClass:"card-col",attrs:{"cols":"auto"}}),_c('v-col',{staticClass:"card-col",attrs:{"cols":"auto","align":"left"}},[_c('Card',{attrs:{"isDirectionLeft":false,"effectEnd":_vm.cardMovement(5),"difference":400,"jobDetails":{
                        companyName: 'Splunk + Sportify data',
                        jobTitle: 'Clark Univeristy',
                        description: _vm.splunk,
                    }}})],1),_c('v-col',{staticClass:"card-col",attrs:{"cols":"auto","align":"right"}},[_c('Card',{attrs:{"isDirectionLeft":true,"effectEnd":_vm.cardMovement(6),"difference":400,"jobDetails":{
                        companyName: 'Hotel Booking',
                        jobTitle: 'Gujarat Technological University',
                        description: _vm.hotel,
                    }}})],1),_c('v-col',{staticClass:"card-col",attrs:{"cols":"auto"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }