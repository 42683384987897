








































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Fraction } from '@/helper/helperIndex';
import { ColorLists, ColorList } from '@/helper/Colors';
import { Snakes, Revent, Organic, Capstone, Splunk, Hotel } from '@/helper/Text';

@Component({
    components: {
        TitleEffect: () => import('@/components/utils/TitleEffect.vue'),
        Card: () => import('@/components/utils/Card.vue'),
    },
})
export default class ProjectLook extends Vue {
    color: ColorList = ColorLists[1];
    scrollVariable: number = 0;
    snakes: string = Snakes;
    revent: string = Revent;
    organic: string = Organic;
    capstone: string = Capstone;
    splunk: string = Splunk;
    hotel: string = Hotel;
    starter: number = 2800;

    onScroll(event: any) {
        this.scrollVariable = event.target.scrollTop;
    }

    cardMovement(serialNo: number) {
        const difference: number = 400;
        return this.starter + serialNo * difference;
    }

    radialEffect(start: number, end: number) {
        let fraction = Fraction(start, end, this.scrollVariable);
        let hex = this.color.hex;
        return `radial-gradient(circle at ${fraction * 100 - 100}%, rgba(${hex}, 0.527) 5%, #eee 35%, #121212 35%)`;
    }
}
